import {
  useRef, useEffect, useMemo, useState
} from 'react';
import {getNaturalDimensionsCurrentSlide} from '../../helpers/study';
import {FOLD_RATIO} from '../../constants/breakpoints';

export const usePrevProps = (props) => {
  const [prevProps, setPrevProps] = useState();

  const ref = useRef();

  useEffect(() => {
    setPrevProps(ref.current);
    ref.current = props;
  }, [props]);

  return prevProps;
};

export function useHorizontalScroll () {
  const elRef = useRef();

  useEffect(() => {
    const el = elRef.current;

    if (el) {
      const onWheel = (e) => {
        if (e.deltaY === 0) return;

        if (
          !(el.scrollLeft === 0 && e.deltaY < 0) &&
          !(el.scrollWidth - el.clientWidth - Math.round(el.scrollLeft) === 0 && e.deltaY > 0)
        ) {
          e.preventDefault();
        }
        el.scrollTo({
          left: el.scrollLeft + e.deltaY
        });
      };

      el.addEventListener('wheel', onWheel);

      return () => el.removeEventListener('wheel', onWheel);
    }
  }, []);

  return elRef;
}

const hundredPercentsValue = 100;

export function useAoiOutOfRange (analysisType, geometry) {
  const [foldHeight, slideH] = useMemo(() => {
    const {slideHeight, slideWidth} = getNaturalDimensionsCurrentSlide();

    const slideRatio = slideWidth / slideHeight;

    const foldRatio = FOLD_RATIO[analysisType];

    let foldH = slideHeight;

    if (foldRatio && slideRatio < foldRatio) {
      foldH = slideWidth / foldRatio;
    }

    return [foldH, slideHeight];
  }, [analysisType, geometry]);

  if (!geometry) return true;

  // do not allow short narrow AOIs
  if (geometry.width === 0 || geometry.height === 0) return true;

  const aoiVerticalPosition =
    (geometry.y * slideH + geometry.height * slideH) / hundredPercentsValue;

  return aoiVerticalPosition > foldHeight;
}

export const PHONE_WIDTH = 768; // Example width for mobile view

export const isMobileView = () => window.innerWidth <= PHONE_WIDTH;

export const useMobileView = () => {
  const [isMobile, setIsMobile] = useState(isMobileView());

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(isMobileView());
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return {isMobile};
};
