export const RECOMMENDATION_STATUS = {
  NOT_REQUESTED: 0,
  SUCCESS: 2,
  ERROR: 3,
  LOADING: 1
};

export const SUPPORTED_GENERATIVE_ANALYSIS_TYPES = ['web', 'mobile', 'general', 'poster'];

export const GENERATIVE_CATEGORIES = {
  ATTENTION: {code: 'attention', title: 'How to get more attention?'},
  CONVERSION: {code: 'conversion', title: 'How to optimize for conversions?'},
  CONSISTENCY: {code: 'consistency', title: 'Validate design consistency?'},
  CONTRAST: {code: 'contrast', title: 'How to improve colors & contrast?'},
  AOI: {code: 'attention-aoi', title: 'How to improve attention?'}
};
