/* eslint-disable no-param-reassign */
import {createReducer} from '@reduxjs/toolkit';
import * as Actions from '../actions';
import {FEATURES_MAP} from '../../../constants/features';

export const initialState = {
  list: {
    data: [],
    meta: {},
    filters: {
      page: 0,
      sortBy: 'date:desc',
      items_per_page: 26,
    },
    didSearch: false,
    isLoading: false,
  },
  fullList: {
    data: [],
    meta: {},
    filters: {
      page: 0,
      sortBy: 'date:desc',
      items_per_page: 25,
    },
    didSearch: false,
    isLoading: false,
  },
  creation: {
    isLoading: false,
    id: '',
    name: '',
    comparisons: {},
    data: {},
  },
  saving: {
    isLoading: false,
    data: {},
  },
  updating: {
    isLoading: false,
    data: {},
  },
  map: FEATURES_MAP.HEATMAP,
  layers: {
    aois: true,
  }
};

export default createReducer(initialState, (builder) => {
  builder
    .addCase(Actions.setComparisonMap, (state, action) => {
      state.map = action.payload;
    })
    .addCase(Actions.toggleFetching, (state, action) => {
      if (action.payload.value) {
        state[action.payload.key][action.payload.value] = action.payload.toggle;

        return;
      }
      state[action.payload.key].isLoading = action.payload.toggle;
    })
    .addCase(Actions.setComparisons, (state, action) => {
      state.list.data = action.payload.data.map((study) => {
        return {
          ...study,
          hasThumb: !!study.studyThumbnail,
        };
      });
      state.list.meta = {
        totalItems: action.payload.meta.total,
        currentPage: action.payload.meta.current_page,
        totalPages: action.payload.meta.last_page,
        perPage: action.payload.meta.per_page,
      };
      state.list.isLoading = false;
      state.list.didSearch = true;
    })
    .addCase(Actions.setComparisonsFullList, (state, action) => {
      state.fullList.data = action.payload.data.map((study) => {
        return {
          ...study,
          hasThumb: !!study.studyThumbnail,
        };
      });
      state.fullList.meta = {
        totalItems: action.payload.meta.total,
        currentPage: action.payload.meta.current_page,
        totalPages: action.payload.meta.last_page,
        perPage: action.payload.meta.per_page,
      };
      state.fullList.isLoading = false;
      state.fullList.didSearch = true;
    })
    .addCase(Actions.setListFilters, (state, action) => {
      state.list.filters = {...state.list.filters, ...action.payload};
    })
    .addCase(Actions.setCreationData, (state, action) => {
      state.creation.isLoading = false;
      state.creation = {...state.creation, ...action.payload};
    })
    .addCase(Actions.clearCreationData, (state) => {
      state.creation.isLoading = false;
      state.creation = initialState.creation;
    })
    .addCase(Actions.showComparisonAoisLayer, (state, action) => {
      state.layers.aois = action.payload;
    });
});
