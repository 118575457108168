import * as Sentry from '@sentry/react';
/* eslint-disable no-magic-numbers */
export const trackUser = () => {
  return !!document.cookie.split(';').filter((item) => {
    return item.indexOf('cookieconsent_status=dismiss') >= 0;
  }).length;
};

export const downloadFile = (url) => {
  const $frame = document.querySelector('#frame-download');

  if ($frame) {
    $frame.remove();
  }

  const frame = document.createElement('frame');

  frame.style.display = 'none';
  frame.id = 'frame-download';
  frame.src = url;
  document.body.appendChild(frame);
};

const RETRIES = 5;

const INTERVAL_MS = 1000;

export const lazyRetry = function (componentImport, retriesLeft = RETRIES, interval = INTERVAL_MS) {
  return new Promise((resolve, reject) => {
    componentImport()
      .then((component) => {
        resolve(component);
      })
      .catch((error) => {
        setTimeout(() => {
          // eslint-disable-next-line no-magic-numbers
          if (retriesLeft === 1) {
            reject(error);

            return;
          }
          // eslint-disable-next-line no-magic-numbers
          lazyRetry(componentImport, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
};

function create_UUID () {
  let dt = new Date().getTime();
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (dt + Math.random() * 16) % 16 | 0;

    dt = Math.floor(dt / 16);

    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });

  return uuid;
}

export function getSessionId () {
  return `${create_UUID()}${Date.now()}`;
}

// Function to detect if the app is running in a WebView
export const isInWebView = () => {
  const userAgent = navigator.userAgent || 'a';

  const standalone = window.navigator.standalone === true;

  const isIOS = /iPhone|iPad|iPod/i.test(userAgent);

  const isAndroid = /Android/i.test(userAgent);

  // const isSafari = /Safari/i.test(userAgent) && !/CriOS/i.test(userAgent);

  const isFacebookApp = /FBAN|FBAV|FB_IAB|FBIOS/i.test(userAgent);

  const isTwitterApp = /Twitter/i.test(userAgent);

  const isInstagramApp = /Instagram/i.test(userAgent);

  // setText(`userAgent:${userAgent},standalone:${standalone},isIOS:${isIOS}`);

  if (isIOS) {
    if (standalone) return false; // iOS PWA

    if (isFacebookApp || isTwitterApp || isInstagramApp) return true; // iOS WebView or In-App Browser
  } else if (isAndroid) {
    if (userAgent.includes('wv') || userAgent.includes('SamsungBrowser')) return true; // Android WebView

    if (isFacebookApp || isTwitterApp || isInstagramApp) return true; // Android In-App Browser
  }

  return false; // Default to not in a WebView
};

// Fetch the binary data from the blob URLs
export const fetchBlobData = async (url) => {
  try {
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.blob();
  } catch (error) {
    Sentry.captureException(error);
    console.error('Error fetching blob data:', error);

    return null;
  }
};
