/* eslint-disable no-undef */
import React, {Suspense} from 'react';
import {createRoot} from 'react-dom/client';
import {HelmetProvider} from 'react-helmet-async';
import {Router} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import {PersistGate} from 'redux-persist/integration/react';
import {Provider} from 'react-redux';
import {IntercomProvider} from 'react-use-intercom';
import './assets/styles/App.scss';
import AppRouter from './AppRouter';
import history from './helpers/router/history';
import store, {persistor} from './utils/store/configure';
// not used at the moment
// import './helpers/i18n';
import AppLoader from './components/Spinners/AppLoader';
import {ViewportProvider} from './contexts/MobileViewContext';
import ErrorBoundary from './components/ErrorBoundary';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_KEY,
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE,
  environment: process.env.REACT_APP_ENVIRONMENT,
  enabled: process.env.REACT_APP_ENVIRONMENT !== 'localhost'
});

const INTERCOM_KEY = process.env.REACT_APP_INTERKOM;

const INTERCOM_DELAY = 2000;

const root = document.getElementById('root');

const reactRoot = createRoot(root);

// Might needed in future
// if (window.trustedTypes) {
//   window.trustedTypes.createPolicy('default', {
//     createScriptURL: (url) => url, // Allow specific URL
//   });
// }

// DEBUG Node removeChild
// if (typeof window !== 'undefined') {
//   // Patch removeChild to log details
//   const originalRemoveChild = Node.prototype.removeChild;

//   Node.prototype.removeChild = function (child) {
//     try {
//       // Log details about the parent and child nodes
//       console.log('React App: Attempting to remove child node:', child);
//       console.log('React App: From parent node:', this);

//       // Proceed with the original method
//       return originalRemoveChild.call(this, child);
//     } catch (error) {
//       console.error('React App Error: Error in removeChild:', error);
//       console.log('React App Error: Problematic parent node:', this);
//       console.log('React App Error: Problematic child node:', child);
//       throw error; // Re-throw the error after logging
//     }
//   };
// }

reactRoot.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Provider store={store}>
        <IntercomProvider appId={INTERCOM_KEY} initializeDelay={INTERCOM_DELAY}>
          <PersistGate loading={null} persistor={persistor}>
            <Suspense fallback={<AppLoader />}>
              <Router history={history}>
                <HelmetProvider>
                  <ViewportProvider>
                    <AppRouter />
                  </ViewportProvider>
                </HelmetProvider>
              </Router>
            </Suspense>
          </PersistGate>
        </IntercomProvider>
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>
);
