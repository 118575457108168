import {getUrlWithToken} from '../../helpers/auth';
import {FOLD_RATIO} from '../../constants/breakpoints';
import {subscribeOnceWithCallback} from '../../services/websocket';

const PIXELS_IN_INCH = 96;

const DPI_PDF = 72;

const PRECISION = 2;

const ONE = 1;

export const inchToPixelsNumber = (inches) => Math.round(inches * PIXELS_IN_INCH);
export const inchToPixels = (inches) => `${inches * (PIXELS_IN_INCH / DPI_PDF)}in`;

export const pptFontToPdf = (pptFont) => `${pptFont * (PIXELS_IN_INCH / DPI_PDF)}pt`;

export function batchify (array, batchSize) {
  const batches = [];

  for (let i = 0; i < array.length; i += batchSize) {
    batches.push(array.slice(i, i + batchSize));
  }

  return batches;
}

export const getPreloadedImageCanvas = async (imageUrl) => {
  const imageElement = new Image();

  const canvasElement = document.createElement('canvas');

  const ctx = canvasElement.getContext('2d');

  imageElement.src = imageUrl;
  await new Promise((resolve, reject) => {
    imageElement.onload = resolve;
    imageElement.onerror = reject;
  });

  canvasElement.width = imageElement.naturalWidth;
  canvasElement.height = imageElement.naturalHeight;

  ctx.drawImage(imageElement, 0, 0);

  return [canvasElement, imageElement];
};

export const getImageData = async (imageUrl) => {
  const [canvasElement] = await getPreloadedImageCanvas(imageUrl);

  return canvasElement.toDataURL();
};

export const getImageSizes = async ({
  clarityBreakpoint,
  imageElement,
  maxWidth,
  maxHeight,
}) => {
  const ratio = imageElement.naturalWidth / imageElement.naturalHeight;

  const isLongImage = !!clarityBreakpoint && ratio < clarityBreakpoint;

  const realHeight = parseFloat((imageElement.naturalHeight / PIXELS_IN_INCH).toFixed(PRECISION));

  const realWidth = parseFloat((imageElement.naturalWidth / PIXELS_IN_INCH).toFixed(PRECISION));

  let width;
  let height;
  let cropHeight = 0;

  if (isLongImage) {
    const realCropHeight = parseFloat(
      (realWidth / clarityBreakpoint).toFixed(PRECISION)
    );

    cropHeight = realCropHeight > maxHeight ? maxHeight : realCropHeight;
    width = Math.min(maxWidth, parseFloat((cropHeight * clarityBreakpoint).toFixed(PRECISION)));
    cropHeight = (realWidth / clarityBreakpoint).toFixed(PRECISION);
    height = parseFloat((width / ratio).toFixed(PRECISION));
  } else if (ratio >= ONE) {
    width = realWidth > maxWidth ? maxWidth : realWidth;
    height = parseFloat((width / ratio).toFixed(PRECISION));
  } else {
    height = realHeight > maxHeight ? maxHeight : realHeight;
    width = parseFloat((height * ratio).toFixed(PRECISION));
  }

  return [width, height, isLongImage, cropHeight];
};

export const getReportImageUrl = async (url) => {
  const newUrl = new URL(getUrlWithToken(url));

  newUrl.protocol = window.location.protocol;
  newUrl.host = window.location.host;
  const image = await fetch(newUrl.toString());

  const imageBlob = await image.blob();

  return URL.createObjectURL(imageBlob);
};

export const getImageOptionsAndSizes = async ({
  url,
  analysisType,
  maxWidth,
  maxHeight
}) => {
  const imageURL = await getReportImageUrl(url);

  const clarityBreakpoint = FOLD_RATIO[analysisType];

  const [canvasElement, imageElement] = await getPreloadedImageCanvas(imageURL);

  const [width, height, isLongImage, cropHeight] = await getImageSizes({
    clarityBreakpoint,
    imageElement,
    maxWidth,
    maxHeight,
  });

  const options = isLongImage ? {
    h: height,
    w: width,
    sizing: {
      type: 'crop',
      h: Math.min(maxHeight, cropHeight),
      w: Math.min(maxWidth, width),
    }
  } : {
    h: height,
    w: width,
    sizing: {
      type: 'contain',
      h: Math.min(maxHeight, height),
      w: Math.min(maxWidth, width),
    }
  };

  const imageData = canvasElement.toDataURL();

  return {
    options, imageData, width, height: cropHeight || height,
  };
};

export const getAnnotatedImageOptionsAndSizes = async ({
  imageUrl,
  analysisType,
  maxWidth,
  maxHeight
}) => {
  const clarityBreakpoint = FOLD_RATIO[analysisType];

  const [canvasElement, imageElement] = await getPreloadedImageCanvas(imageUrl);

  const [width, height, isLongImage, cropHeight] = await getImageSizes({
    clarityBreakpoint,
    imageElement,
    maxWidth,
    maxHeight,
  });

  const options = isLongImage ? {
    h: height,
    w: width,
    sizing: {
      type: 'crop',
      h: Math.min(maxHeight, cropHeight),
      w: Math.min(maxWidth, width),
    }
  } : {
    h: height,
    w: width,
    sizing: {
      type: 'contain',
      h: Math.min(maxHeight, height),
      w: Math.min(maxWidth, width),
    }
  };

  const imageData = canvasElement.toDataURL();

  return {
    options, imageData, width, height: cropHeight || height,
  };
};

export const chunkArray = (array, size) => {
  const result = [];

  let index = 0;
  while (index < array.length) {
    result.push(array.slice(index, index + size));
    index += size;
  }

  return result;
};

export const fetchHeatmapAoi = async (dataItem) => {
  const renderResponse = await fetch(
    getUrlWithToken(dataItem['heatmapUrl-aoi-render'])
  );

  const {channel} = await renderResponse.json();

  const heatmapAoi = await new Promise((resolve) => {
    subscribeOnceWithCallback(channel, resolve);
  });

  return heatmapAoi;
};

export const delay = (time) => {
  return new Promise((resolve) => setTimeout(resolve, time));
};
