const HUNDRED_PERCENT = 100;

const PERCENT_DECIMALS = 1;

const IMAGE_QUALITY = 0.95;

/**
 * Draws AOI annotations on a canvas context
 * @param {CanvasRenderingContext2D} ctx - The canvas 2D context
 * @param {Object} annotation - The annotation object containing geometry and data
 * @param {number} imageWidth - The width of the image
 * @param {number} imageHeight - The height of the image
 */
export const drawSingleAoi = async (ctx, annotation, imageWidth, imageHeight) => {
  const {geometry, data} = annotation;

  // Convert percentage to actual pixels
  const x = (geometry.x / HUNDRED_PERCENT) * imageWidth;

  const y = (geometry.y / HUNDRED_PERCENT) * imageHeight;

  const width = (geometry.width / HUNDRED_PERCENT) * imageWidth;

  const height = (geometry.height / HUNDRED_PERCENT) * imageHeight;

  // Set colors based on benchmark
  if (data?.benchmark?.colorIndicator === 'red') {
    ctx.strokeStyle = '#FA7269';
    ctx.fillStyle = '#FA7269';
  } else if (data?.benchmark?.colorIndicator === 'yellow') {
    ctx.strokeStyle = '#FAE630';
    ctx.fillStyle = '#FAE630';
  } else if (data?.benchmark?.colorIndicator === 'green') {
    ctx.strokeStyle = '#0EB128';
    ctx.fillStyle = '#0EB128';
  } else {
    ctx.strokeStyle = '#FFFFFF';
    ctx.fillStyle = '#FFFFFF';
  }

  // Draw rectangle
  ctx.lineWidth = 2;
  ctx.strokeRect(x, y, width, height);

  // Draw label if text exists
  if (data.text?.length > 0) {
    const label = `${data.text} ${(data.aoiValue * HUNDRED_PERCENT).toFixed(PERCENT_DECIMALS)}%`;

    drawAoiLabel(ctx, label, x, y);
  }
};

/**
 * Draws a label for an AOI
 * @param {CanvasRenderingContext2D} ctx - The canvas 2D context
 * @param {string} label - The label text
 * @param {number} x - The x coordinate
 * @param {number} y - The y coordinate
 */
const drawAoiLabel = (ctx, label, x, y) => {
  ctx.font = '12px Montserrat';
  const metrics = ctx.measureText(label);

  const padding = 8;

  const boxHeight = 20;

  const boxOffsetX = 1;

  const paddingCoef = 2;

  const textOffsetY = 6;

  // Draw background box
  ctx.save();
  const originalFillStyle = ctx.fillStyle;

  ctx.fillRect(
    x - boxOffsetX,
    y - boxHeight,
    metrics.width + (padding * paddingCoef),
    boxHeight
  );

  // Draw text
  ctx.fillStyle = '#000000';
  ctx.fillText(label, x + padding, y - textOffsetY);
  ctx.fillStyle = originalFillStyle;
  ctx.restore();
};

/**
 * Creates a canvas with annotations drawn on an image
 * @param {HTMLImageElement} image - The source image
 * @param {Array} annotations - Array of annotation objects
 * @returns {HTMLCanvasElement} Canvas element with the image and annotations
 */
export const createAnnotatedCanvas = async (image, annotations) => {
  const canvas = document.createElement('canvas');

  canvas.width = image.width;
  canvas.height = image.height;

  const ctx = canvas.getContext('2d');

  // Draw original image
  ctx.drawImage(image, 0, 0);

  // Draw all annotations
  annotations.forEach(async (annotation) => drawSingleAoi(ctx, annotation, image.width, image.height));

  return canvas;
};

/**
 * Creates and downloads an annotated image
 * @param {Blob} imageBlob - The original image as a blob
 * @param {Array} annotations - Array of annotation objects
 * @param {string} filename - The name for the downloaded file
 * @returns {Promise} Promise that resolves when the download starts
 */

export const downloadAnnotatedImage = (imageBlob, annotations, filename) => {
  return new Promise((resolve, reject) => {
    const img = new Image();

    const tempUrl = window.URL.createObjectURL(imageBlob);

    img.onload = async () => {
      const canvas = await createAnnotatedCanvas(img, annotations);

      canvas.toBlob((blob) => {
        const downloadUrl = window.URL.createObjectURL(blob);

        const link = document.createElement('a');

        link.href = downloadUrl;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // Cleanup
        window.URL.revokeObjectURL(downloadUrl);
        window.URL.revokeObjectURL(tempUrl);
        resolve();
      }, 'image/jpeg', IMAGE_QUALITY);
    };

    img.onerror = reject;
    img.src = tempUrl;
  });
};
/**
 * Creates a URL for an annotated image
 * @param {Blob} imageBlob - The original image as a blob
 * @param {Array} annotations - Array of annotation objects
 * @returns {Promise<string>} Promise that resolves with the annotated image URL
 */
export const createAnnotatedImageUrl = (tempUrl, annotations) => {
  return new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = async () => {
      const canvas = await createAnnotatedCanvas(img, annotations);

      canvas.toBlob((blob) => {
        window.URL.revokeObjectURL(tempUrl);
        const imageUrl = window.URL.createObjectURL(blob);

        resolve(imageUrl);
      }, 'image/jpeg', IMAGE_QUALITY);
    };

    img.onerror = reject;
    img.src = tempUrl;
  });
};
