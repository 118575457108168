/* eslint-disable no-param-reassign */
import {createAction, createReducer, createSelector} from '@reduxjs/toolkit';
import get from 'lodash/get';
import * as Sentry from '@sentry/browser';
import {parseFirstError} from '../../helpers/auth';
import {toast} from '../../utils/toast';
import Api from '../../services/api';
import {_getStudyDetails} from '../studies';
import {getSelectedCompany} from '../settings';
import {getReportImageUrl, chunkArray} from '../../components/Reports/helpers';
import {createAnnotatedImageUrl} from '../../utils/aoi/draw';

export const setProjectReportData = createAction('REPORTS/SET_PROJECT_DATA');
export const setProjectReportInfo = createAction('REPORTS/SET_PROJECT_INFO');
export const openReportModal = createAction('REPORTS/OPEN_REPORT_MODAL');
export const setProjectReportLoadingState = createAction('REPORTS/SET_PROJECT_LOADING_STATE');
export const setProjectReportSelectorData = createAction('REPORTS/SET_SELECTOR_DATA');
export const resetReportsStore = createAction('REPORTS/RESET');
export const setReportsImages = createAction('REPORTS/SET_IMAGES');
export const startReportImageGeneration = createAction('REPORTS/START_IMAGES_GENERATION');
export const setReportOptionsTemplate = createAction('REPORTS/SET_REPORT_OPTIONS_TEMPLATE');
export const setReportOptionsSlide = createAction('REPORTS/SET_REPORT_OPTIONS_SLIDE');
export const setReportOptionsLanguage = createAction('REPORTS/SET_REPORT_OPTIONS_LANGUAGE');

const FILTERS = {
  page: 0,
  sortBy: 'created_at:desc',
  items_per_page: 99999
};

export const initialState = {
  projectReport: {
    isOpen: false,
    isLoading: false,
    project: {
      id: null,
      name: null
    },
    data: [],

    selectorData: null
  },
  isModalOpen: false,
  images: {
    heatmapsAoi: {},
    heatmaps: {},
    heatmapsAoiSizes: {}
  },
  isImageGenerating: false,
  options: {
    template: 'attention',
    slides: {
      heatmap: true,
      clarity: true,
      focus: true,
      contrast: true,
      aoi: true,
      recommendations: true
    },
    language: 'en'
  }
};

export default createReducer(initialState, (builder) => {
  builder
    .addCase(setReportOptionsLanguage, (state, action) => {
      state.options.language = action.payload;
    })
    .addCase(setReportOptionsTemplate, (state, action) => {
      state.options.template = action.payload;
    })
    .addCase(setReportOptionsSlide, (state, action) => {
      const {id, checked} = action.payload;

      state.options.slides[id] = checked;
    })
    .addCase(setProjectReportInfo, (state, action) => {
      const {id, name} = action.payload;

      state.projectReport.project.id = id;
      state.projectReport.project.name = name;
      state.projectReport.isOpen = !!id;
    })
    .addCase(openReportModal, (state) => {
      state.isModalOpen = true;
    })
    .addCase(setProjectReportSelectorData, (state, action) => {
      state.projectReport.selectorData = action.payload;
    })
    .addCase(setProjectReportData, (state, action) => {
      state.projectReport.data = action.payload;
    })
    .addCase(setProjectReportLoadingState, (state, action) => {
      state.projectReport.isLoading = action.payload;
    })
    .addCase(setReportsImages, (state, action) => {
      state.images = action.payload;
    })
    .addCase(startReportImageGeneration, (state) => {
      state.isImageGenerating = true;
    })
    .addCase(resetReportsStore, (state) => {
      // initial store except options, those will remember
      return {...initialState, options: state.options};
    });
});

// fetch list from API and set to state
export const getProjectReportAnalysisList = (projectId) => async (dispatch, getState) => {
  try {
    const company = getSelectedCompany(getState());

    if (!company) {
      return;
    }

    const {id: companyId} = company;

    const {data} = await Api.projects.getById({companyId, projectId, filters: FILTERS});

    dispatch(setProjectReportSelectorData(data.data));
  } catch (err) {
    if (err) {
      // dispatch(resetReportsStore());
      toast.error('Couldn\'t fetch your project data');
    }
  }
};

export const getReportData = (ids) => async (dispatch) => {
  dispatch(setProjectReportLoadingState(true));

  try {
    const dataPromises = ids.map((id) => Api.studies.getById(id));

    const allData = await Promise.all(dataPromises);

    const payload = allData.map(({data}) => {
      if (typeof data.meta.study.benchmark.benchmark !== 'undefined') {
        data.meta.study.benchmark.benchmark = JSON.parse(data.meta.study.benchmark.benchmark);
      }

      return data;
    });

    dispatch(setProjectReportData(payload));
  } catch (err) {
    Sentry.captureException(err);
    const error = parseFirstError(err, 'Couldn\'t get reportdata');

    toast.error(error);
  } finally {
    dispatch(setProjectReportLoadingState(false));
  }
};

// draw aoi images in batches of aois
export const fetchAnnotatedImages = async (data) => {
  // defines how much aois displayed in one slide
  const AOI_BATCH_SIZE = 4;

  const urls = {};

  const aois = {};

  await Promise.all(data.map(async (analysis) => {
    const id = analysis.meta?.analysisId;

    const annotations = analysis.data?.[0]?.annotations || [];

    const heatmap = analysis.data?.[0]?.heatmapUrl;

    if (annotations.length > 0) {
      try {
        const annotationBatches = chunkArray(annotations, AOI_BATCH_SIZE);

        const batchUrls = await Promise.all(annotationBatches.map(async (batch) => {
          const heatmapUrl = await getReportImageUrl(heatmap);

          return createAnnotatedImageUrl(heatmapUrl, batch);
        }));

        urls[id] = batchUrls;

        const annotationsWithRecommendations = annotations.filter(
          (annotation) => annotation.data?.generative
        );

        const recommendations = await Promise.all(annotationsWithRecommendations.map(async (annotation) => {
          const heatmapUrl = await getReportImageUrl(heatmap);

          const recommendationAnnotatedUrl = await createAnnotatedImageUrl(heatmapUrl, [annotation]);

          return {...annotation, url: recommendationAnnotatedUrl};
        }));

        aois[id] = recommendations;
      } catch (error) {
        console.error(`Error processing analysis ${id}:`, error);
      }
    }
  }));

  return {aoiImages: urls, aoiRecommendationsImages: aois};
};

// draw all aois in one image
export const fetchAllAnnotatedImages = async (data) => {
  const urls = {};

  await Promise.all(data.map(async (analysis) => {
    const id = analysis.meta?.analysisId;

    const annotations = analysis.data?.[0]?.annotations || [];

    const heatmap = analysis.data?.[0]?.heatmapUrl;

    if (annotations.length > 0) {
      try {
        const heatmapUrl = await getReportImageUrl(heatmap);

        urls[id] = await createAnnotatedImageUrl(heatmapUrl, annotations);
      } catch (error) {
        console.error(`Error processing analysis ${id}:`, error);
      }
    }
  }));

  return {aoiImages: urls};
};

export const fetchHeatmapImages = async (data) => {
  const heatmaps = {};

  await Promise.all(data.map(async (analysis) => {
    const id = analysis.meta?.analysisId;

    const heatmap = analysis.data?.[0]?.heatmapUrl;

    heatmaps[id] = await getReportImageUrl(heatmap);
  }));

  return {heatmaps};
};

export const fetchFocusImages = async (data) => {
  const focus = {};

  await Promise.all(data.map(async (analysis) => {
    const id = analysis.meta?.analysisId;

    const focusImage = analysis.data?.[0]?.focus_image;

    if (!focusImage) return;

    focus[id] = await getReportImageUrl(focusImage);
  }));

  return {focus};
};

export const fetchContrastImages = async (data) => {
  const contrasts = {};

  await Promise.all(data.map(async (analysis) => {
    const id = analysis.meta?.analysisId;

    const contrastImage = analysis.data?.[0]?.contrast_image;

    if (!contrastImage) return;

    contrasts[id] = await getReportImageUrl(contrastImage);
  }));

  return {contrasts};
};

export const _selectReportsState = (state) => state?.reports;

export const _selectProjectReport = (state) => _selectReportsState(state)?.projectReport;

export const selectProjectReportLoadingState = createSelector(
  _selectProjectReport,
  (projectReport) => get(projectReport, 'isLoading', false)
);

// eslint-disable-next-line max-len
export const selectProjectReportInfo = createSelector(_selectProjectReport, (projectReport) => get(projectReport, 'project', {}));

// eslint-disable-next-line max-len
export const selectProjectReportStatus = createSelector(_selectProjectReport, (projectReport) => get(projectReport, 'isOpen', false));

// eslint-disable-next-line max-len
export const selectProjectReportData = createSelector(_selectProjectReport, (projectReport) => get(projectReport, 'data', []).map(_getStudyDetails));

export const selectProjectReportSelectorData = createSelector(
  _selectProjectReport,
  (projectReport) => get(projectReport, 'selectorData', null)
);

export const selectReportModalOpenStatus = createSelector(
  _selectReportsState,
  _selectProjectReport,
  (reports, projectReport) => reports?.isModalOpen || get(projectReport, 'isOpen', false)
);

export const selectReportImages = createSelector(_selectReportsState, (reports) => reports?.images);
